import { GoogleCategory } from "../../../app/services/secured/global";
import useCategories from "../../../app/hooks/useCategories";
import { Autocomplete, Box, createFilterOptions, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React, { useMemo } from "react";

type GoogleCategorySelectProps = {
  defaultSelected: GoogleCategory | null,
  onChange: (categoryId: number) => void,
  label?: boolean,
  disabled?: boolean,
  size?: "small" | "medium" | "large",
}
export const GoogleCategorySelect = ({
  defaultSelected, onChange, label = true, disabled = false, size = "small"
}: GoogleCategorySelectProps) => {

  console.log(defaultSelected)

  const categories = useCategories();
  const options = useMemo(() => categories.map(c => ({ label: c.title, id: c.id })), [categories]);

  const defaultValue = defaultSelected ? { label: defaultSelected.title, id: defaultSelected.id } : null;

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 500,
  });

  return (
    <Box>
      <Autocomplete
        key={`default-${defaultValue?.id}`}
        disabled={disabled}
        defaultValue={defaultValue}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ? <FormattedMessage id={"category"} /> : undefined}
          />)}
        options={options}
        onChange={(e, val) => {
          if (val) {
            //@ts-ignore
            onChange(val.label)
          }
        }}
        filterOptions={filterOptions}
      />
    </Box>
  )
}
