import { AvailableCompany } from "../../../../../app/types/campaign";
import { FeedOptions, ShopAccounts, UserFeed } from "../../../../../app/types/feed";
import { AppDispatch } from "../../../../../app/store";
import g_ads from "../../../../../assets/img/icons/gads.svg";
import google from "../../../../../assets/img/icons/google.svg";
import {
  modalShowCatalogPixel,
  modalShowInitAccount,
  modalShowLinkAccount,
  modalShowMerchantCenter,
  modalShowMicrosoftCenter
} from "../../../../../app/slices/modal";
import { FormattedMessage } from "react-intl";
import merchant_center from "../../../../../assets/img/merchant_center.svg";
import { adsCompanies } from "../../../../../app/slices/campaign/constants";
import facebook from "../../../../../assets/img/icons/facebook.svg";
import microsoft from "../../../../../assets/img/icons/microsoft.svg";
import fc_shopping_dark from "../../../../../assets/img/logo/fc_shopping_dark.svg";
import React from "react";
import { Service } from "./ChannelServices";

export const getChannelsServices = (
  company: AvailableCompany | "feedcast",
  currFeed: UserFeed | null,
  accounts: ShopAccounts,
  services: any,
  dispatch: AppDispatch,
  feedOptions: FeedOptions,
  handleFreeListing: () => void,
) => {
  const serviceConfigs: Record<AvailableCompany | "feedcast", Service[]> = {
    "google": [
      {
        id: currFeed?.platforms?.ads?.code ?? "",
        name: currFeed?.platforms?.ads?.name ?? "",
        type: "configuration",
        logo: g_ads,
        provider: "go",
        service: "Google Ads",
        status: accounts.hasLinkedAccount.google ? "online" : "offline",
        code: "google_ads",
        reset: true,
        config: {
          action: () => dispatch(modalShowInitAccount()),
          disabled: accounts.hasLinkedAccount.google,
          cta: <FormattedMessage id={accounts.hasLinkedAccount.google ? "account-linked" : "configure-account"} />,
        },
      },
      {
        id: services.merchantCenter.data?.merchant?.id ?? "",
        name: services.merchantCenter.data?.merchant?.name ?? "",
        type: "configuration",
        logo: merchant_center,
        provider: "go",
        service: "Merchant Center",
        status: services.merchantCenter.data?.merchant?.warnings.length
          ? "alerts"
          : services.merchantCenter.data?.merchant
            ? !services.merchantCenter.data?.merchant?.is_child // means that's not a sub account
              ? "online"
              : accounts.canCreateCampaigns.google
                ? "online"
                : "offline"
            : "offline",
        code: "m_center",
        config: {
          action: () => dispatch(modalShowMerchantCenter()),
          disabled: !accounts.hasLinkedAccount.google,
          show: currFeed?.platforms?.merchant?.is_child,
          cta: accounts.canCreateCampaigns.google
            ? <FormattedMessage id={"merchant-center-information"} />
            : <FormattedMessage id={"verify-account"} />
        },
        alerts: services.merchantCenter.data?.merchant?.warnings ?? [],
      },
      {
        id: "",
        name: "",
        type: "free-listing",
        logo: google,
        provider: "go",
        service: "Free Listing",
        code: "free_listing",
        status: feedOptions.enable_free_listing ? "online" : "offline",
        config: {
          action: handleFreeListing,
          disabled: !accounts.canCreateCampaigns.google,
          cta: !accounts.canCreateCampaigns.google
            ? <FormattedMessage id={'need-complete-configuration'} />
            : <FormattedMessage id={'handle-free-listing'} />
        }
      }
    ],
    "meta": [
      {
        id: currFeed?.platforms?.meta?.code.toString() ?? "",
        name: currFeed?.platforms?.meta?.name ?? "",
        provider: "fb",
        type: "configuration",
        logo: adsCompanies.meta.image,
        service: "Business Manager",
        status: accounts.hasLinkedAccount.meta ? "online" : "offline",
        code: "meta_account",
        reset: true,
        config: {
          action: !accounts.hasLinkedAccount.meta
            ? () => dispatch(modalShowLinkAccount({ company: "meta" }))
            : undefined
          ,
          disabled: accounts.hasLinkedAccount.meta,
          cta: <FormattedMessage id={accounts.hasLinkedAccount.meta ? "account-linked" : "configure-account"} />,
        },
      },
      {
        id: services.metaCatalog.data?.catalog?.id ?? "",
        name: services.metaCatalog.data?.catalog?.name ?? "",
        type: "configuration",
        provider: "fb",
        logo: facebook,
        service: "Catalogue Produit",
        status: services.metaCatalog.data?.catalog ? "online" : "offline",
        code: "meta_pixel",
        config: {
          action: () => dispatch(modalShowCatalogPixel()),
          disabled: !accounts.hasLinkedAccount.meta,
          cta: accounts.canCreateCampaigns.meta
            ? <FormattedMessage id={"catalog-linked"} />
            : <FormattedMessage id={"link-catalog"} />
        },
      }
    ],
    "microsoft": [
      {
        id: currFeed?.platforms?.microsoft?.id ?? "",
        name: currFeed?.platforms?.microsoft?.name ?? "",
        type: "configuration",
        provider: "bg",
        logo: microsoft,
        service: "Microsoft Ads",
        status: accounts.hasLinkedAccount.microsoft ? "online" : "offline",
        code: "microsoft",
        reset: true,
        config: {
          action: () => dispatch(modalShowLinkAccount({ company: "microsoft" })),
          disabled: accounts.hasLinkedAccount.microsoft,
          cta: <FormattedMessage id={accounts.hasLinkedAccount.microsoft ? "account-linked" : "configure-account"} />,
        },
      },
      {
        id: currFeed?.platforms?.microsoft_merchant?.id ?? "",
        name: currFeed?.platforms?.microsoft_merchant?.name ?? "",
        type: "configuration",
        provider: "bg",
        logo: microsoft,
        service: "Merchant Center",
        status: accounts.canCreateCampaigns.microsoft ? "online" : "offline",
        code: "microsoft-center",
        config: {
          action: () => dispatch(modalShowMicrosoftCenter()),
          disabled: !accounts.hasLinkedAccount.microsoft || accounts.canCreateCampaigns.microsoft,
          cta: "Merchant Center",
        },
      },
    ],
    "feedcast": [
      {
        id: "",
        name: "",
        type: "marketplace",
        provider: null,
        logo: fc_shopping_dark,
        service: "Feedcast Marketplace",
        status: (((currFeed?.last_stat?.imported || 0) > 0) && feedOptions.export_comparator) ? "online" : "offline",
        code: "feedcast-marketplace",
      }
    ],
  };

  return serviceConfigs[company] as Service[] || [];
};
