import React from "react";
import { FormattedMessage } from "react-intl";
import { MakeCategoryName } from "../../../../App/Blocks/Misc";
import { ProductData } from "../../../../../app/types/products";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Launch } from "@mui/icons-material";
import { useProductCategory, useProductCategoryString } from "../../../../../app/hooks/useCategories";
import { GoogleCategorySelect } from "../../../../App/Blocks/GoogleCategorySelect";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  getProductCustomData,
  getProductData,
  getProductDataSource,
  getProductView,
  setCustomData
} from "../../../../../app/slices/product";

const ProductDatas = () => {

  const view = useAppSelector(getProductView);

  const dispatch = useAppDispatch();
  const productData = useAppSelector(getProductData);
  const customData = useAppSelector(getProductCustomData);
  const onChange = (c: Partial<ProductData>) => dispatch(setCustomData(c));

  const category = useProductCategoryString(productData?.google_product_category_k?.toString());
  // const category = productData?.google_product_category_k ?? null;
  const disableInputs = useAppSelector(getProductDataSource) === "origin";

  const handleIdentifierExists = (s: string) => {
    if (s.length) {
      onChange({
        ...customData,
        gtin: s,
        identifier_exists: "yes",
      });
    } else {
      const { identifier_exists, ...cleanData } = customData;
      onChange({ ...cleanData, gtin: "", identifier_exists: "" });
    }
  }

  // const categoryName = MakeCategoryName(category?.id);
  const categorySelector = (
    <GoogleCategorySelect
      disabled={disableInputs}
      defaultSelected={category}
      onChange={(categoryName) => onChange({ ...customData, google_product_category_k: categoryName })}
    />
  );

  const handleNoIdentifier = (e: any, checked: boolean) => {
    onChange({ ...customData, identifier_exists: checked ? "no" : "" });
  }

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={view === "page" ? 6 : 12}>
        <FormControl fullWidth>
          <TextField
            label={<FormattedMessage id={"gtin"} />}
            defaultValue={productData?.gtin}
            inputProps={{
              type: "number",
            }}
            disabled={disableInputs}
            onChange={(e) => handleIdentifierExists(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <FormControlLabel
                    control={(
                      <Switch
                        disabled={(customData.gtin?.length || 0) > 0}
                        checked={customData.identifier_exists === "no"}
                        onChange={handleNoIdentifier}
                      />
                    )}
                    labelPlacement={"start"}
                    label={(
                      <Typography variant={"caption"} color={"text.secondary"}>
                        <FormattedMessage id={'no-unique-identifier'} />
                      </Typography>
                    )}
                  />
                </InputAdornment>
              )
            }}
          />
        </FormControl>
      </Grid2>

      <Grid2 xs={view === "page" ? 6 : 12}>
        <FormControl fullWidth>
          <TextField
            // size={"small"}
            label={"MPN"}
            defaultValue={productData?.mpn}
            disabled={disableInputs}
            onChange={(e) => onChange({ ...customData, mpn: e.target.value })}
          />
        </FormControl>
      </Grid2>
      <Grid2 xs={12}>
        <FormControl fullWidth>
          <TextField
            disabled={disableInputs}
            label={<FormattedMessage id={"brand"} />}
            defaultValue={productData?.brand_k}
            onChange={(e) => onChange({ ...customData, brand_k: e.target.value })}
          />
        </FormControl>
      </Grid2>
      <Grid2 xs={12}>
        <FormControl fullWidth>
          {disableInputs
            ? productData?.google_product_category_k
            : categorySelector
          }
        </FormControl>
      </Grid2>
      <Grid2 xs={12}>
        <FormControl fullWidth>
          <TextField
            label={<FormattedMessage id={"product-link"} />}
            defaultValue={productData.link}
            disabled={disableInputs}
            onChange={(e) => onChange({ ...customData, link: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <Tooltip arrow placement={"top"} title={<FormattedMessage id={"visit-product"} />}>
                    <IconButton
                      component={'a'}
                      target={'_blank'}
                      href={productData.link}
                      disableRipple
                      edge={"end"}
                    >
                      <Launch />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </FormControl>
      </Grid2>
    </Grid2>
  )
}

export default ProductDatas;
