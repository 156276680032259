import { useAppSelector } from "../hooks";
import { getCurrentFeed } from "../slices/app";
import globalApi, { GoogleCategory } from "../services/secured/global";
import { useEffect, useMemo } from "react";

const useCategories = () => {
  const currFeed = useAppSelector(getCurrentFeed);
  const [getCategories, categories] = globalApi.useLazyCategoriesQuery();

  useEffect(() => {
    if (currFeed !== null) {
      const lang = currFeed.locale?.split("_");
      if (lang && lang[0]?.length) {
        getCategories({ language: lang[0] }, true)
      }
    }
  }, [currFeed?.locale]);

  const categoriesData = useMemo(() => categories.data, [categories.data]);

  return categoriesData ?? [];
}

export const useProductCategory = (categoryId?: number): GoogleCategory | null => {
  const categories = useCategories();
  return categoryId ? categories.find(c => c.id === parseInt(categoryId.toString())) ?? null : null;
}

export const useProductCategoryString = (categoryId?: string): GoogleCategory | null => {
  const categories = useCategories();
  return categoryId ? categories.find(c => c.title === categoryId) ?? null : null;
}

export default useCategories;
